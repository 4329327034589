
import _ from 'lodash';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

import uiMixin from '../mixins/ui';
import ActionCard from './action-card';

const TOUCH_DISTANCE_THRESHOLD = 30;
const TRANSITION_BLUR_MAX = 15;

export default {
  name: 'ActionSelector',
  components: {
    ActionCard,
  },
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      activeIndex: 0,
      actionCardWidthHalf: 0,
      touchStartX: undefined,
      touchDistance: undefined,
    };
  },
  computed: {
    storeList() {
      return this.$store.state.actionSelect.list;
    },
    hasActions() {
      return this.storeList.length > 0;
    },
    actionSelectedId() {
      return this.storeList[this.activeIndex];
    },
    canNavigate() {
      return this.storeList.length > 1;
    },
    canGoPrevious() {
      return this.activeIndex > 0;
    },
    canGoNext() {
      return this.activeIndex < this.storeList.length - 1;
    },
    touchDistancePercent() {
      let percent = 0;

      if (typeof this.touchDistance === 'number') {
        percent = _.clamp((Math.abs(this.touchDistance) / this.actionCardWidthHalf) * 100, 0, 100);
      }

      return percent;
    },
    transitionScaleX() {
      return (100 - this.touchDistancePercent) / 100;
    },
    transitionBlur() {
      return (this.touchDistancePercent / 100) * TRANSITION_BLUR_MAX;
    },
    actionSelectorContentStyle() {
      return {
        transform: `scaleX(${this.transitionScaleX})`,
        filter: `blur(${this.transitionBlur}px)`,
        userSelect: this.touchDistancePercent > 0 ? 'none' : 'auto',
      };
    },
  },
  watch: {
    storeList() {
      if (this.activeIndex > this.storeList.length - 1) {
        this.activeIndex = 0;
      }
    },
    actionSelectedId() {
      this.animate();
    },
  },
  mounted() {
    this.activeIndex = this.$store.state.actionSelect.indexStart;
    this.handleResize();
    Haptics.impact({ style: ImpactStyle.Medium });

    if (window) {
      window.addEventListener('resize', this.handleResize);
    }

    /* These don't conflict with other views, so we can pass isExclusive=false
     * A better solution would be to adopt a bubbling model like native JS events
     */
    this.$bindKeys.bind('action-selector', {
      ArrowLeft: () => {
        this.handlePrevious();
      },
      ArrowRight: () => {
        this.handleNext();
      },
    }, false);
  },
  destroyed() {
    this.$bindKeys.unbind('action-selector');

    if (window) {
      window.removeEventListener('resize', this.handleResize);
    }
  },
  methods: {
    handleResize() {
      if (this.hasActions) {
        this.actionCardWidthHalf = Math.round(this.$refs['action-selector-content'].clientWidth / 2);
      }
    },
    handleReset() {
      this.activeIndex = 0;
    },
    handlePrevious() {
      if (this.activeIndex === 0) {
        this.handleLast();
      } else {
        this.activeIndex = this.activeIndex - 1;
      }
    },
    handleLast() {
      this.activeIndex = this.storeList.length - 1;
    },
    handleNext() {
      if (this.activeIndex >= this.storeList.length - 1) {
        this.handleReset();
      } else {
        this.activeIndex = this.activeIndex + 1;
      }
    },
    handleTouchStart(e) {
      if (!this.isDesktop && e) {
        let x = e.clientX;

        if (typeof x === 'undefined' && e.touches && e.touches.length > 0) {
          x = e.touches[0].clientX;
        }

        this.touchStartX = x;
      }
    },
    handleTouchMoving(e) {
      if (!this.isDesktop && e && typeof this.touchStartX === 'number') {
        let x = e.clientX;

        if (typeof x === 'undefined' && e.touches && e.touches.length > 0) {
          x = e.touches[0].clientX;
        }

        this.touchDistance = x - this.touchStartX;
      }
    },
    handleTouchEnd(e) {
      if (!this.isDesktop && e && typeof this.touchStartX === 'number') {
        let x = e.clientX;

        if (typeof x === 'undefined' && e.changedTouches && e.changedTouches.length > 0) {
          x = e.changedTouches[0].clientX;
        }

        this.touchDistance = x - this.touchStartX;

        if (this.touchDistance > TOUCH_DISTANCE_THRESHOLD) {
          this.handlePrevious();
        } else if (this.touchDistance < -1 * TOUCH_DISTANCE_THRESHOLD) {
          this.handleNext();
        }
      }

      this.touchStartX = undefined;
      this.touchDistance = undefined;
    },
    animate() {
      if (this.$store.state.ui.isAdvancedUIEnabled) {
        this.$anime({
          targets: [
            '#action-selector-content',
          ],
          duration: 400,
          easing: 'easeOutCubic',
          scaleX: [0, 1],
          filter: ['blur(15px)', 'blur(0px)'],
        });
      }
    },
  },
};
